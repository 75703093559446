body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
}

.homepage {
  background-color: #f6ddd8;
}

.homepage .logo {
  width: 500px;
}

.homepage .logo img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.about .page-wrapper {
  -webkit-align-items: center;
          align-items: center;
}

.about .photo {
  width: 400px;
  height: 400px;
  margin-right: 40px;
  background-color: #2d2d2d;
  border-radius: 50%;
}

.about .bio {
  width: 400px;
}

.contact {
  background-color: #f6ddd8;
  -webkit-flex-flow: column;
          flex-flow: column;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  line-height: 20px;
  font-size: 18px;
}

.contact .contact-logo {
  width: 400px;
  margin-bottom: 20px;
}

.gallery .image-gallery-image {
  text-align: center;
}

.image-gallery {
  width: 100%;
  height: auto;
}

.image-gallery-slide img {
  height: 100%;
  max-height: 100vh;
  object-fit: contain;
  overflow: hidden;
  object-position: center center;
}


.page-wrapper {
  display: -webkit-flex;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

@media (max-width: 575.98px) {
  .about .photo {
    margin: 0;
  }
}

@media (max-width: 767.98px) {
  .about .photo {
    margin: 0;
  }
}

@media (max-width: 991.98px) {}

@media (max-width: 1199.98px) {}

